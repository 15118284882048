(function (window, $) {
  var strings = BatchGeoStrings.getStringsForComponent("BatchGeoFormErrors");

  /**
   * Check if we have errors on the page, and if so displays an error modal, this is separate
   * from using javascript to VALIDATE a form. This function only looks for PHP generated
   * errors inside of the form_error hidden input.
   */
  var checkFormErrors = function () {
    var formMessage = $("#" + this.settings.formErrorsInputId).val();

    if (formMessage && formMessage.length > 0) {
      var messages = formMessage.split("###");
      this.displayModalError(messages);
    }
  };

  /**
   * Builds a list of errors to display with classes for styling
   * @param {array} messages Any array of strings for messages to display
   */
  var createModalErrorMessage = function (messages) {
    messages = _.castArray(messages);
    var $wrapper = $("<div>");
    var $messageContainer = $(
      '<div class="' + this.settings.modalMessageContainerClass + '">',
    );
    var $errorList = $("<ul>");
    messages.forEach(function (message) {
      $errorList.append("<li>" + message + "</li>");
    });
    const $modalTitle = $("<p>").text(this.settings.modalErrorTitle);
    $messageContainer.append([$modalTitle, $errorList]);
    $wrapper.append($messageContainer);
    return $wrapper.html();
  };

  /**
   * BatchGeoFormErrors object handles displaying and validating form errors on individual pages
   * @param {object} config Configurations for the object will merge and override defaults
   */
  var BatchGeoFormErrors = function (config) {
    var options = config || {};
    var self = this;
    this.settings = _.merge(
      {
        modalErrorTitle: strings.get("MODAL_ERROR_TITLE"),
        formErrorsInputId: "form_error",
        modalMessageContainerClass: "form-error-modal",
        formValidators: [],
      },
      options,
    );

    // These must be privileged methods outside of settings otherwise the 'this' keyword is undefined
    this.createModalErrorMessage = options.createModalErrorMessageFn
      ? options.createModalErrorMessageFn
      : createModalErrorMessage;
    this.checkFormErrors = options.checkFormErrorsFn
      ? options.checkFormErrorsFn
      : checkFormErrors;

    // Perform any initialization code here. If it needs to rely on the page elements, put it inside
    // the onReady function later in this file.
    BatchGeo.timer("init");

    // Convenience helper to run the ready function when page is ready
    $(document).ready(function () {
      self.onReady();
    });
  };

  /**
   * validateForm runs the validation handlers corresponding with the formKey that was given to the
   * BatchGeoFormErrors object when it was constructed. If no key is found, the form validates
   * successfully.
   *
   * Example setup:
   * var formErrors = new BatchGeoFormErrors({
   *   formValidators: [
   *     {
   *       formKey: 'addSubUser',
   *       handlers: [
   *         function() {
   *           return !$("#signup_first_name").val().length ? 'Please add a first name.' : false;
   *         }
   *       ]
   *     }
   *   ]
   * })
   *
   * Usage:
   * <form onsubmit="return formErrors.validate('userSubAddForm')">
   * ...
   *
   * @param {string} formKey The key associated with the validate handlers in the settings object
   */
  BatchGeoFormErrors.prototype.validateForm = function (formKey) {
    var messages = [];
    this.settings.formValidators.forEach(function (validator) {
      if (formKey === validator.formKey) {
        validator.handlers.forEach(function (handler) {
          // The handler should either return a string of the error, or false for no error.
          var returnValue = handler();
          if (returnValue) {
            messages.push(returnValue);
          }
        });
      }
    });

    if (messages.length) {
      this.displayModalError(messages);
      return false;
    }

    return true;
  };

  /**
   * Displays a custom alert with the contents returned from the createModalErrorMessageFn function
   */
  BatchGeoFormErrors.prototype.displayModalError = function (messages) {
    BatchGeo.customAlert(this.createModalErrorMessage(messages));
  };

  BatchGeoFormErrors.prototype.onReady = function () {
    BatchGeo.timer("onReady");

    this.checkFormErrors();
  };

  // Expose the constructor if we need separate instances
  window.BatchGeoFormErrors = BatchGeoFormErrors;
})(window, jQuery);
