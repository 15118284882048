(function (window) {
  /**
   * BatchGeoInvoiceGenerator generates manages the whole Invoice Generator
   * view. This includes the list/table view of all transactions, the form to
   * customize your invoice and even the caching of values in those forms.
   *
   * @param {Object} options
   *   @param {string} options.receiptsListHtml The HTML that will be injected
   *   into the receiptsList selector element. This should be a plain string
   *   of HTML like "<h1>hello world</h1>" and NOT a jQuery or DOM object.
   *   @param {number} options.animationLength This is a number in
   *   milliseconds to animate the fades between the pages.
   *   @param {Object} options.selectors A hash of all supported selectors
   *   with their selector string.
   *   	@param {string} options.selectors.receiptsList The element to add
   *   	the HTML from the receiptsListHtml option.
   *   	@param {string} options.selectors.customInfoContainer The element
   *   	containing the form to customize the invoice.
   *   	@param {string} options.selectors.adminMapTable The element
   *   	containing the table of all the invoices and receipts a user has.
   *   	@param {string} options.selectors.customInfo Any element with this
   *   	selector will have it's value cached on keyup and then loaded in on
   *   	page load.
   *   	@param {string}	options.selectors.backButton The selector that will
   *   	go back from the customization screen to the list of receipts.
   *   	@param {string}	options.selectors.generateButton The selector that
   *   	when clicked will go from the receiptList to the invoice
   *   	customization form.
   *   	@param {string} options.selectors.customInvoiceForm The selector
   *   	thatbwhen clicked will generate an invoice with the custom fields.
   *
   * @constructor
   */
  var BatchGeoInvoiceGenerator = function (options) {
    this.settings = $.extend(
      true,
      {
        receiptsListHtml: "",
        animationLength: 300,
        selectors: {
          receiptsList: "fieldset.receipts",
          customInfoContainer: ".custom-info-container",
          adminMapTable: ".admin-map-table.data",
          customInfo: ".custom-info",
          backButton: ".back-button",
          generateButton: ".generate-btn",
          customInvoiceForm: "#submit-generate",
        },
      },
      options,
    );

    this.init();
  };

  /**
   * Will render the BatchGeoInvoiceGenerator component. This will render the
   * HTML as well as attach all event handlers. It will also redirect the
   * page automatically based on the URL.
   */
  BatchGeoInvoiceGenerator.prototype.init = function () {
    var self = this;

    $(this.settings.selectors.receiptsList).replaceWith(
      this.settings.receiptsListHtml,
    );

    // Init the caching layer
    this.persistence = new BatchGeoPersistence({ key: "CustomInvoice" });

    // Init the handlers
    $(this.settings.selectors.customInfo).on("keyup", function (event) {
      self.customInfoHandler(event);
    });

    $(this.settings.selectors.backButton).on("click", function (event) {
      self.backButtonHandler(event);
    });

    $(this.settings.selectors.generateButton).on("click", function (event) {
      self.generateButtonHandler(event);
    });

    $(this.settings.selectors.customInvoiceForm).on("submit", function (event) {
      self.customInvoiceSubmitHandler(event);
    });

    window.addEventListener("hashchange", function () {
      self.hashChangeHandler();
    });

    // Once everything is setup if there's a hash in the URL redirect to the
    // customization page
    if (this.getPageIdFromUrlHash() !== "") {
      this.goToPage(this.getPageIdFromUrlHash());
    }
  };

  /**
   * When called this will loop through BatchGeoPersistence (localStorage) and
   * and find the matching inputs on the page and insert the cached value.
   */
  BatchGeoInvoiceGenerator.prototype.loadCachedInputValues = function () {
    var cachedInputValues = this.persistence.fetch();
    Object.keys(cachedInputValues).forEach(function (name) {
      $(this.settings.selectors.customInfo)
        .filter("[name=" + name + "]")
        .val(cachedInputValues[name]);
    }, this);
  };

  /**
   * No-op method to open the Admin Map Table view. This is the view that
   * shows all history of all the invoices.
   */
  BatchGeoInvoiceGenerator.prototype.openAdminMapTable = function () {
    var selectors = this.settings.selectors;
    var animationLength = this.settings.animationLength;
    $(selectors.customInfoContainer).fadeOut(animationLength, function () {
      BatchGeo.goToHash();
      $(selectors.adminMapTable).fadeIn(animationLength);
    });
  };

  /**
   * Opens the given custom invoice page. It takes an ID of the invoice page
   * to go to.
   *
   * @param {string} pageId The page ID to go to.
   */
  BatchGeoInvoiceGenerator.prototype.openCustomInfoContainer = function (
    pageId,
  ) {
    var self = this;
    var selectors = self.settings.selectors;
    var animationLength = self.settings.animationLength;
    $(selectors.adminMapTable).fadeOut(animationLength, function () {
      self.loadCachedInputValues();
      BatchGeo.goToHash(pageId);
      $(selectors.customInfoContainer).fadeIn(animationLength);
    });
  };

  /**
   * Will open the given page. This triggers a click on the element with the
   * matching `name` property. This is janky. This should instead be some sort
   * of page object or something.
   *
   * @param {string} pageId A string to represent the page ID to redirect to
   */
  BatchGeoInvoiceGenerator.prototype.goToPage = function (pageId) {
    $("a[name=" + pageId + "]").click();
  };

  /**
   * Will get the page ID by parsing the URL hash.
   *
   * @return {string}
   */
  BatchGeoInvoiceGenerator.prototype.getPageIdFromUrlHash = function () {
    return window.location.href.split("#!/")[1];
  };

  /**
   * Handles when the custom info inputs are changed by saving the values into
   * BatchGeoPersistence (localStorage). It expects an input element's event.
   *
   * @param {Object} event A jQuery event object (must contain `target`!)s
   */
  BatchGeoInvoiceGenerator.prototype.customInfoHandler = function (event) {
    this.persistence.set($(event.target).attr("name"), $(event.target).val());
  };

  /**
   * Handles back button functionality which goes back to the invoice list.
   */
  BatchGeoInvoiceGenerator.prototype.backButtonHandler = function (event) {
    event.preventDefault();
    this.openAdminMapTable();
  };

  /**
   * Handles opening the customization page for a given invoice ID based on
   * the elements's `name` property. It expects an <a> element with a `name`
   * and `href` prop.
   *
   * @param {Object} event A jQuery event object (must contain `target`!)s
   */
  BatchGeoInvoiceGenerator.prototype.generateButtonHandler = function (event) {
    this.openCustomInfoContainer($(event.target).attr("name"));
    var transValue = $(event.target)
      .attr("href")
      .match(/TRANS=([\w\d%]+)/)[1];
    $(this.settings.selectors.customInvoiceForm)
      .find("[name=TRANS]")
      .val(transValue);
    var checkValue = $(event.target)
      .attr("href")
      .match(/check=([\w\d%]+)/)[1];
    $(this.settings.selectors.customInvoiceForm)
      .find("[name=check]")
      .val(checkValue);
    event.preventDefault();
  };

  /**
   * Generates the URL based on the input values filled out on the
   * customization page/form. It then redirects the user to that page which is
   * the PDF for printing and downloading.
   *
   * @param {Object} event A DOM event object with `preventDefault()`.
   */
  BatchGeoInvoiceGenerator.prototype.customInvoiceSubmitHandler = function (
    event,
  ) {
    // Will generate something like: &input1=input%20;value&input2=blah this
    // uses jQuery's map so it needs to be converted to a normal JS Array
    // type then it's joined to make one long string.
    var inputUrl = $(this.settings.selectors.customInvoiceForm)
      .find("[data-custom-info]")
      .map(function (index, input) {
        return encodeURI("&" + input.name + "=" + input.value);
      })
      .toArray()
      .join("");

    var transValue = $(this.settings.selectors.customInvoiceForm)
      .find("[name=TRANS]")
      .val();
    var checkValue = $(this.settings.selectors.customInvoiceForm)
      .find("[name=check]")
      .val();

    BatchGeo.redirect(
      "/admin/receipts/?template=generate&TRANS=" +
        transValue +
        "&check=" +
        checkValue +
        inputUrl,
    );
    event.preventDefault();
  };

  /**
   * A no-op method to manage when the hash changes in the URL. If there is a
   * page ID in the hash then it will open the customization page. If not it
   * will open the list of all invoices. If the ID is an empty string it means
   * that it's like `#!/` so we clear out the hash completely to make the URL
   * a bit nicer.
   */
  BatchGeoInvoiceGenerator.prototype.hashChangeHandler = function () {
    var pageId = this.getPageIdFromUrlHash();

    // If there's a page ID open the custom info container. If there is no
    // ID then we want to open the main table page
    pageId ? this.openCustomInfoContainer(pageId) : this.openAdminMapTable();

    // Just to make cleaner URLs. Now when you go back it'll be
    // admin/receipts/ instead of admin/receipts/#
    if (pageId === "") BatchGeo.goToHash();
  };

  window.BatchGeoInvoiceGenerator = BatchGeoInvoiceGenerator;
})(window);
