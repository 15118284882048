(function (window) {
  /**
   * A component for BatchGeoAdminTable that creates a loader that is shown and
   * hidden when the AdminMapTable.loaderVisibility BatchGeoStore property is
   * changed to/from true/false.
   *
   * @param {object} options An options hash
   *   @param {string|jQuery} options.container A selector string or jQuery
   *   object of where to append the loader DOM into
   *   @param {string} options.template A string to be converted into DOM that
   *   is injected into the `container` given
   *
   * @example
   *
   * new BatchGeoAdminMapTableLoader({
   *   container: '.admin-map-table-loader-wrapper'
   * });
   *
   * // Then to make it show... (or hide with `false`)
   * BatchGeoStore.dispatch({
   *   type: 'ADMIN_MAP_TABLE_LOADER_VISIBILITY',
   *   visibility: true
   * });
   *
   * @constructor
   */
  var BatchGeoAdminMapTableLoader = function (options) {
    var self = this;
    this.settings = $.extend(
      {
        container: null,
        template:
          "" +
          '<div class="admin-map-table-loader">' +
          '<div class="admin-map-table-loader-bounce1"></div>' +
          '<div class="admin-map-table-loader-bounce2"></div>' +
          '<div class="admin-map-table-loader-bounce3"></div>' +
          "</div>",
      },
      options,
    );

    BatchGeoStore.addListener(
      function (state) {
        return state.AdminMapTable.loaderVisibility;
      },
      function (newVisibility, oldVisibility) {
        return newVisibility !== oldVisibility;
      },
      function (newVisibility) {
        self._$element.toggle(newVisibility);
      },
    );

    this.render();
  };

  /**
   * Renders the loader and creates a reference to that element.
   * @private
   */
  BatchGeoAdminMapTableLoader.prototype.render = function () {
    this._$element = $(this.settings.template).appendTo(
      this.settings.container,
    );
  };

  /**
   * Destroys the loader and removes it from the DOM
   */
  BatchGeoAdminMapTableLoader.prototype.destroy = function () {
    this._$element.remove();
    delete this._$element;
  };

  window.BatchGeoAdminMapTableLoader = BatchGeoAdminMapTableLoader;
})(window);
