(function (window) {
  /**
   * Adds a cookie notice to the bottom of the page. Clicking the X to close
   * will prevent it from showing again until localStorage is cleared. It is
   * not saved to the account but just client side localStorage only.
   *
   * @param {object} options The options hash for the component
   * @param {jQuery|string|HTMLElement} [options.container=$('body')] A jQuery
   * element, selector or HTMLElement to be used as the container to append the
   * notice into. By default this will just go into the body
   * @param {string} [options.noticeHiddenLocalStorageName='BatchGeoCookieNoticeHidden']
   * The localStorage key to write to. It will write "true" to this key and
   * if it's true will NOT show the cookie warning anymore.
   */
  var BatchGeoCookieNotice = function (options) {
    this.settings = $.extend(
      true,
      {
        container: $("body"),
        noticeHiddenLocalStorageName: "BatchGeoCookieNoticeHidden",
      },
      options,
    );

    // Don't show the notice in iframes
    // Don't show on mobile maps
    if (
      window.BatchGeo.isInIframe() ||
      (window.BatchGeoDevices.is("mobile") &&
        window.BatchGeoStore.getState().App.isMap)
    )
      return;

    // Note: everything saved in localStorage is a string!
    try {
      const searchParams = new URLSearchParams(window.location.search);
      if (!searchParams.get("output")) {
        if (
          localStorage &&
          localStorage[this.settings.noticeHiddenLocalStorageName] != "true"
        ) {
          this.render();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Renders the warning onto the page. You can call this to override the
   * localStorage hidden value (such as if a user wants to read the notice
   * again or you erase localStorage and want to pop it up again.)
   *
   * It will add a $noticeElement to the prototype so you can access the
   * element directory if needed.
   */
  BatchGeoCookieNotice.prototype.render = function () {
    var self = this;
    this.$noticeElement = $("<div />", {
      class: "batchgeo-cookie-notice",
      html: [
        $("<button />", {
          text: "×",
          click: function () {
            self.close();
          },
        }),
        $("<p />", {
          html: BatchGeoStrings.getStringsForComponent(
            "BatchGeoCookieNotice",
          ).get("MESSAGE"),
        }),
      ],
    }).appendTo($(this.settings.container));
  };

  /**
   * It removes the element and then sets the localStorage value to true so it
   * will not show on the next load.
   */
  BatchGeoCookieNotice.prototype.close = function () {
    this.$noticeElement.remove();

    // Note: Safari will error out if you try to write to localstorage in
    // private browsing mode so try it here.
    try {
      localStorage[this.settings.noticeHiddenLocalStorageName] = true;
    } catch (e) {
      console.log(e);
    }
  };

  window.BatchGeoCookieNotice = BatchGeoCookieNotice;
})(window);

// Until we get parcel.js or some build tool in here we can just execute this
// immediately since we don't really have a "site/index.js" file that applies
// to all site files.
$(function () {
  if (!window.batchGeoCookieNotice) {
    window.batchGeoCookieNotice = new window.BatchGeoCookieNotice();
  }
});
