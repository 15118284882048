BatchGeoStore.addReducer("AdminMapTable", function (state, action) {
  state = state || {
    // Tracks the ordering of the sort where 0 == none, 1 == A-Z, 2 == Z-A
    sortOrder: 0,
    // The name of the column to sort
    keyToSort: "",
    // A function that you can use to modify how the data is returned. For
    // example dates you could `return new Date(data).getTime()
    sortableData: function (data) {
      return data;
    },
    // This stores ALL rows and is NOT meant to be modified in any way except to
    // to add or remove rows or to update existing row data
    allRows: [],
    // This stores the display of the rows. This is the one to sort, filter, etc
    displayRows: [],
    // Tracks the current filter
    filter: "maps",
    // Tracks if the loader overlay should be on or off
    loaderVisibility: false,
    // Stores the AdminMapTable notification bar. Contains the type and message
    notification: {
      type: "info",
      message: "",
    },
  };

  switch (action.type) {
    case "ADMIN_MAP_TABLE_ACTIVE_FILTER":
      return Object.assign({}, state, {
        filter: action.filter,
      });

    // This will increment the sorting order so it will either flip it or reset
    // the sort.
    case "ADMIN_MAP_TABLE_SORT_ORDER_INCREMENT":
      var newState = _.merge({}, state, action);

      if (state.keyToSort !== action.key) {
        newState.sortOrder = 0;
      }

      switch (newState.sortOrder) {
        // 0 means there is NO sort, so then we need sort it from A-Z
        case 0:
        // 1 means an A-Z sort, so flip it to Z-A
        // eslint-disable-next-line no-fallthrough
        case 1:
          newState.displayRows.sort(function (a, b) {
            // For sorting, most people don't care or expect capitals to be sorted so
            // we lowercase everything while sorting.
            a = newState.sortableData(a[action.key]);
            b = newState.sortableData(b[action.key]);
            if (a < b) {
              // We check the sort order here to see if it should be reverse sorted
              return newState.sortOrder === 0 ? -1 : 1;
            } else if (a > b) {
              return newState.sortOrder === 1 ? -1 : 1;
            }
            return 0;
          });

          ++newState.sortOrder;
          break;
        // 2 means a Z-A sort so we need to reset the sort back to the original
        // sorting order
        case 2:
          newState.displayRows = state.allRows;
          newState.sortOrder = 0;
          break;
      }

      newState.keyToSort = action.key;

      return newState;

    case "ADMIN_MAP_TABLE_SORT_ORDER_RESET":
      return _.merge({}, state, {
        sortOrder: 0,
        keyToSort: "",
        displayRows: state.allRows,
      });

    case "ADMIN_MAP_TABLE_LOADER_VISIBILITY":
      return Object.assign({}, state, { loaderVisibility: action.visibility });

    case "ADMIN_MAP_TABLE_ROWS_ADD":
      var mergedAllRows = state.allRows.concat(action.rows);
      var mergedDisplayRows = state.displayRows.concat(action.rows);
      var addRowsFilter = function (row, index, self) {
        return index == self.indexOf(row);
      };

      return Object.assign({}, state, {
        allRows: mergedAllRows.filter(addRowsFilter),
        displayRows: mergedDisplayRows.filter(addRowsFilter),
      });

    // This updates data on an existing row
    case "ADMIN_MAP_TABLE_ROWS_UPDATE":
      // Create an array of all the IDs of maps to update
      var ids = action.rows.map(function (row) {
        return row.map_id;
      });

      var updateRowsMap = function (row) {
        var index = ids.indexOf(row.map_id);
        // If the ID matches an ID in the given list...
        if (index > -1) {
          // We use Object.assign to merge/overwrite the new data
          return Object.assign({}, row, action.rows[index]);
        } else {
          return row;
        }
      };

      return Object.assign({}, state, {
        allRows: state.allRows.map(updateRowsMap),
        displayRows: state.displayRows.map(updateRowsMap),
      });

    case "ADMIN_MAP_TABLE_ROWS_REMOVE":
      var removeRowsFilter = function (row) {
        return action.rowIds.indexOf(row.map_id) == -1;
      };
      return Object.assign({}, state, {
        allRows: state.allRows.filter(removeRowsFilter),
        displayRows: state.displayRows.filter(removeRowsFilter),
      });

    // This will overwrite ALL row data. Includes displayRows!
    case "ADMIN_MAP_TABLE_ROWS_UPDATE_ALL":
      return Object.assign({}, state, {
        allRows: action.rows,
        displayRows: action.rows,
      });

    // Use this to filter down the display rows
    case "ADMIN_MAP_TABLE_ROWS_FILTER":
      return Object.assign({}, state, {
        displayRows: action.rows,
      });

    case "ADMIN_MAP_TABLE_ROWS_FILTER_RESET":
      return Object.assign({}, state, {
        displayRows: state.allRows,
      });

    case "ADMIN_MAP_TABLE_NEW_NOTIFICATION":
      action.notification = action.notification || state.notification;

      return Object.assign({}, state, {
        notification: {
          type: action.notification.type || state.notification.type,
          message: action.notification.message || state.notification.message,
        },
      });

    default:
      return state;
  }
});
