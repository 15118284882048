(function (window) {
  /**
   * BatchGeoSearch is a component that will generate search functionality,
   * including the elements and other niceties like a X button to clear the
   * search. It searches a given data collection from BatchGeoStore for the
   * given query, not text blocks.
   *
   * @param {object} options An options hash
   *   @param {string|jQuery} options.container The containing element that
   *   contains the inline notification
   *
   *   @param {string} placeholder The text to put in the search input as a
   *   placeholder for the empty search box
   *
   *   @param {function} batchGeoStoreDataSource A function that should return a
   *   BatchGeoStore state object. It is used as the first parameter for
   *   BatchGeoStore.addListener(batchGeoStoreDataSource, ..., ...)
   *
   *   @param {function} dataIndexer This return value is what's actually
   *   searched. It's the search index. Since the data property can be anything
   *   (i.e. binary data, URLs, HTML, etc) the dataIndexer function allows
   *   you to return searchable strings. For example if you passed binary image
   *   data for data[0] and then passed "dog" in dataIndexer[0] and a user typed
   *   "dog" the binary dog image will be returned to you in the result callback
   *
   *   @param {function} onQuery This is called as a user types, or "queries",
   *   the search box. It returns the user's query.
   *
   *   @param {function} onResult The callback that is fired for *every* object
   *   for every search. The `element` param returns the object searched and
   *   `wasMatch` returns a boolean
   *
   *   @param {function} onResults The callback that is fired after every
   *   element is checked. This is fired after the last onResult is fired.
   *
   *   @param {function} onClear This callback will be called whenever the
   *   search is cleared out with clearSearchResults or destroy or the user
   *   clicks the clear button. The elements you pass for searching are returned
   *   here for convenience.
   *
   * @example
   * new BatchGeoSearch({
   *   container: this.elements.searchContainer,
   *
   *   placeholder: 'Map Name',
   *
   *   batchGeoStoreDataSource: function (state) {
   *     return state.MyStateToWatch.someProperty;
   *   },
   *
   *   dataIndexer: function(data) {
   *      // Here we're indexing the "text" field in the data
   *     return data.map(function (item) {
   *       return data.text
   *     })
   *   },
   *
   *   onQuery: function (query) {
   *     // Add or remove the active class based on if there's a query
   *     this.$elements.searchInput.toggleClass('active', query !== '');
   *   },
   *
   *   onResult: function ($element, wasMatch) {
   *     // Add or remove class on elements that match search
   *     $element.toggleClass('match', wasMatch);
   *   },
   *
   *   onResults: function (results) {
   *     // Append the results to the results list
   *     $('.results-list').append(results.map(function (item) {
   *       return '<p>' + item.title + '</p>';
   *     }).join(''))
   *   },
   *
   *   onClear: function () {
   *     // Remove the active class when the search is cleared out
   *     this.$elements.searchInput.removeClass('active');
   *   }
   * });
   *
   * @constructor
   */
  function BatchGeoSearch(options) {
    var self = this;

    this.settings = _.merge(
      {
        container: "",

        placeholder: "Search",

        batchGeoStoreDataSource: function (state) {
          return state;
        },

        dataIndexer: function (_rows) {
          return [];
        },

        onQuery: function (_query) {},
        onResult: function (_element, _wasMatch) {},
        onResults: function (_matches) {},
        onClear: function (_elements) {},
      },
      options,
    );

    // Adds the listener that automatically reindexes whenever the given data
    // store from the settings is altered
    BatchGeoStore.addListener(
      this.settings.batchGeoStoreDataSource,
      function (newResults, oldResults) {
        return !_.isEqual(newResults, oldResults);
      },
      function (newResults) {
        self._data = newResults;
        self._indexResults();
      },
    );

    self._data = this.settings.batchGeoStoreDataSource(
      BatchGeoStore.getState(),
    );
    this._indexResults();
    this.render();
  }

  /**
   * Returns and sets the returned data from options.dataIndexer on _indexedData
   * @returns {array} An array of searchable data
   * @private
   */
  BatchGeoSearch.prototype._indexResults = function () {
    return (this._indexedData = this.settings.dataIndexer.call(
      this,
      this._data,
    ));
  };

  /**
   * The render method generates all the DOM for the search component and
   * attaches all the DOM events needed to those generated elements.
   */
  BatchGeoSearch.prototype.render = function () {
    this.$elements = {};

    // Create our own wrapper inside of the user given container
    this.$elements.wrapper = $(this._createSearchWrapper()).appendTo(
      this.settings.container,
    );

    // Generate the actual search input and put it into this instance's wrapper
    this.$elements.searchInput = $(this._createSearchInput()).appendTo(
      this.$elements.wrapper,
    );
    this._initSearchInputListener();

    // Generate the reset search button and attach the click listener
    this.$elements.searchResetButton = $(
      this._createSearchResetButton(),
    ).appendTo(this.$elements.wrapper);
    this._initSearchResetButtonListener();
  };

  /**
   * Ths clearSearchResults method will clear out the search and reset
   * everything back to it's original state. The clear button will hide, the
   * search box will be cleared, and it will call the onClear callback so you
   * can clean up your search results back to how they were before searching.
   */
  BatchGeoSearch.prototype.clearSearchResults = function () {
    // Hide the clear search box button
    this.$elements.searchResetButton.toggle(false);

    // Make the search input empty again
    this.$elements.searchInput.val("");

    // Call the clear callback so users can clean up their results
    this.settings.onClear.call(this, this._data);
  };

  /**
   * Remove the component from the DOM completely. Since all events are tied to
   * the generated elements, no unbinding will need to be done manually.
   */
  BatchGeoSearch.prototype.destroy = function () {
    this.clearSearchResults();
    this.$elements.wrapper.remove();
  };

  /**
   * The handler for the actual searching as a user is typing
   * @private
   */
  BatchGeoSearch.prototype._initSearchInputListener = function () {
    var self = this;

    this.$elements.searchInput.on(
      "input",
      _.debounce(function () {
        self.settings.onQuery.call(self, $(this).val());
        // On every keyup check if the value of the search input is an empty
        // string or not. If it is, we need to hide the X reset button. If there's
        // any text in the input we show the reset button.
        self.$elements.searchResetButton.toggle($(this).val() !== "");
        // Here we actually trigger a search on the DOM
        self._search($(this).val());
      }, 200),
    );
  };

  /**
   * This handles what needs to happen when a user interacts with the clear
   * search button.
   * @private
   */
  BatchGeoSearch.prototype._initSearchResetButtonListener = function () {
    var self = this;
    this.$elements.searchResetButton.on("click", function () {
      self.clearSearchResults();
    });
  };

  /**
   * This method searches for the given value in the elements passed in to
   * search. It will call onResult for every element and tell the user if it was
   * a match or not. It will also call onResults with all the results found.
   *
   * @param {string} value The value to look for in all the elements
   *
   * @private
   */
  BatchGeoSearch.prototype._search = function (value) {
    var self = this;

    var results = this._indexedData.map(function (item, index) {
      var wasMatch = false;

      // Checks if the search contains a quoted string like "foo" and not foo.
      // If it is a quoted string we check if it matches *exactly* the text
      // that is in the search box
      if (/^"(.)+"$/gi.test(value)) {
        wasMatch = item == value.replace(/"/g, "");
      }
      // If not we check if the text for the element matches *any* part of the
      // text that is in the search box
      else {
        wasMatch = item.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      }

      // Tell the user about the result of the search on this element
      self.settings.onResult.call(self, self._data[index], wasMatch);

      return wasMatch;
    });

    self.settings.onResults.call(
      self,
      self._data.filter(function (item, index) {
        return results[index];
      }),
    );
  };

  /**
   * Generates a generic wrapper for this BatchGeoSearch component
   * @returns {jQuery} A jQuery element
   * @private
   */
  BatchGeoSearch.prototype._createSearchWrapper = function () {
    return $("<div />", {
      class: "batchgeo-search-input-wrapper",
    });
  };

  /**
   * Generates the search input and applies the user's placeholder to it
   * @returns {jQuery} a jQuery element
   * @private
   */
  BatchGeoSearch.prototype._createSearchInput = function () {
    return $("<input />", {
      class: "batchgeo-search-input",
      placeholder: this.settings.placeholder,
    });
  };

  /**
   * Generates the search input reset button that clears out the search
   * @returns {jQuery} a jQuery element
   * @private
   */
  BatchGeoSearch.prototype._createSearchResetButton = function () {
    return $("<button />", {
      class: "batchgeo-search-reset-button",
      html: "&#215;",
      css: {
        display: "none",
      },
    });
  };

  window.BatchGeoSearch = BatchGeoSearch;
})(window);
