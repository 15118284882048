/**
 * BatchGeoAdminMapTable provides the interactivity for the user's map list. It
 * handles everything from infinite scrolling (via Clusterize), to header row
 * sorting to live search.
 */
(function (window) {
  var strings = window.BatchGeoStrings.getStringsForComponent(
    "BatchGeoAdminMapTable",
  );

  /**
   * Simply takes a date string and converts it to the format of MM/DD/YYYY. It
   * uses leading 0s as well so 1/1/2011 becomes 01/01/2011. It will remove any
   * timestamps so it only parses the date, not the datetime.
   *
   * @param {string} dateString A string that JavaScript's `Date` can parse
   * @returns {string} Returns a string in this format: '01/31/2016'
   */
  var formatMapDate = function (dateString) {
    var mapDate = new Date(dateString);

    // The date must be reformatted to work on iOS devices if it's using the
    // dash format. They don't support the dates in a `yyyy-mm-dd hh:mm:ss`
    // format which is how we get it here.
    if (dateString.split("-").length === 3) {
      var dateParts = dateString.substring(0, 10).split("-");
      mapDate = new Date(
        dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0],
      );
    }

    // This is a "clever" one liner to get the leading 0 for when month or day
    // returns a single digit like 1. We append a 0 always and use slice to drop
    // it if we don't need it. For example, if you have `12`, we create `012`
    // then slice(-2) will make it become 12. If you had `1`, we create `01` and
    // slice will keep it `01`.
    // Also worth noting is that getUTCMonth and getMonth return 0-11, NOT 1-12.
    // for this reason we must add a +1 so the month is returned in the way the
    // user expects.
    var month = ("0" + (mapDate.getUTCMonth() + 1)).slice(-2);
    var day = ("0" + mapDate.getUTCDate()).slice(-2);
    var year = mapDate.getUTCFullYear().toString().substring(2, 4);
    return month + "/" + day + "/" + year;
  };

  /**
   * This is the constructor for BatchGeoAdminMapTable. It takes options in the
   * form of an object that is merged with defaults.
   *
   * @param {object} options The options to pass to the constructor
   *   @param {object} options.data The data to convert to an HTML table
   *   @param {object} options.container The containing element that holds the
   *   table and search
   *   @param {function} options.clusterChanged A callback that fires whenever
   *   clusterize loads new rows into the DOM and removes the others.
   *   @param {object} options.template A function that runs for each row that
   *   you build the HTML for that row with.
   *
   * @example
   * new window.BatchGeoAdminMapTable({data: admin_map_data_list});
   *
   * @constructor
   */
  var BatchGeoAdminMapTable = function (options) {
    var self = this;
    this.settings = _.merge(
      {
        data: [],
        container: "",
        clusterChanged: function () {},
        template: function () {
          return "";
        },
      },
      options,
    );

    var formattedData = this._formatData(this.settings.data);

    BatchGeoStore.dispatch({
      type: "ADMIN_MAP_TABLE_ROWS_UPDATE_ALL",
      rows: formattedData,
    });

    this.elements = {
      tableWrap: $(this.settings.container).find(".admin-map-table-wrap")[0],
      tableContent: $(this.settings.container).find(
        ".admin-map-table-content",
      )[0],
      searchContainer: $(this.settings.container).find(
        ".admin-map-table-search",
      )[0],
      noResultsContainer: $(this.settings.container).find(
        ".admin-map-table-no-results",
      )[0],
    };

    this._windowResizeHandler = function () {
      $(self.elements.tableWrap).css("max-height", $(window).height());
    };

    $(window).on("resize", this._windowResizeHandler).trigger("resize");

    // A sorting listener that fires if the sort order was changed or a
    // different column got sorted
    BatchGeoStore.addListener(
      function (state) {
        return state.AdminMapTable;
      },
      function (newState, oldState) {
        return (
          newState.sortOrder !== oldState.sortOrder ||
          newState.keyToSort !== oldState.keyToSort
        );
      },
      function (newState, oldState) {
        var $wrap = $(self.elements.tableWrap);

        var currentArrow = "";
        switch (newState.sortOrder) {
          case 1:
            // Up arrow
            currentArrow = "&#9650";
            break;
          case 2:
            // Down arrow
            currentArrow = "&#9660";
            break;
        }

        // This has to be first since if they are the same keyToSort the new
        // arrow would get wiped out
        $wrap
          .find('[data-sort-key="' + oldState.keyToSort + '"]')
          .find(".admin-map-table-sort-arrow")
          .html("");

        $wrap
          .find('[data-sort-key="' + newState.keyToSort + '"]')
          .find(".admin-map-table-sort-arrow")
          .html(currentArrow);
      },
    );

    // A general display listener that is listening for changes in the *display*
    // of the rows. When a change happens it will format the new data and then
    // will update clusterize and the table and scroll you up top again
    BatchGeoStore.addListener(
      function (state) {
        return state.AdminMapTable.displayRows;
      },
      function (newRows, oldRows) {
        return !_.isEqual(newRows, oldRows);
      },
      function (newRows) {
        var formattedData = self._formatData(newRows);
        self._clusterize.update(self._renderTableFromTemplate(formattedData));
        self.elements.tableWrap.scrollTop = 0;
      },
    );

    // If allRows changes then we most likely got a brand new list (like
    // switching from not archived to /all and if we got a new list we should
    // clear our the search.
    BatchGeoStore.addListener(
      function (state) {
        return state.AdminMapTable.allRows;
      },
      function (newRows, oldRows) {
        return !_.isEqual(newRows, oldRows);
      },
      function () {
        self._batchGeoSearch.clearSearchResults();
      },
    );

    this._buildSortingHeaders();
    this._newClusterizeInstance(this._renderTableFromTemplate(formattedData));
    this._initSearch();
  };

  /**
   * Given an array of rows, this helper function will generate a text
   * representation of the row's HTML. It will compile an array for every row
   * given with that HTML that you can then use to set via innerHTML, jQuery's
   * append()/.html(), or something like clusterize.
   *
   * This expects the following properties on the row objects in the array:
   * - alias (the ID for building links)
   * - title (the title of the map, defaults to "(untitled)" if undefined/empty)
   * - date_update (in a JS parseable format)
   * - map_id (the map ID to build the edit link)
   * - secret (the secret, hashed ID to build the edit link)
   *
   * The array would look something like this:
   *
   * [
   *  {
   *    title: 'Hello World',
   *    alias: '123abc',
   *    date_updated: '1/1/1 12:12:12',
   *    map_id: '1234567890',
   *    secret: 'e807f1fcf82d132f9bb018ca6738a19f'
   *   }
   * ]
   *
   * @param {array} rows The array of rows (objects) to convert to HTML
   * @returns {array} Returns an array of HTML strings for each of the rows
   *
   * @private
   */
  BatchGeoAdminMapTable.prototype._renderTableFromTemplate = function (rows) {
    return rows.map(this.settings.template);
  };

  /**
   * Loops through the given data from settings and then formats certain
   * properties like the data to be in mm/dd/yyyy format or empty titles to have
   * "(untitled)" there
   *
   * @param {array} data An array of data to format. Currently it looks for a
   * `title` and `date_updated` property.
   * @returns {array} It returns the same data given except in it's newly
   * formatted state
   *
   * @private
   */
  BatchGeoAdminMapTable.prototype._formatData = function (data) {
    const dataCopy = Object.assign([], data);
    // TODO(tom) this should probably just be a mapping function, instead of forEach
    dataCopy.forEach(function (row) {
      row.title =
        row.title ||
        '<span class="italic">(' + strings.get("UNTITLED_ROW") + ")</span>";
      row.date_updated = formatMapDate(row.date_updated);
    });
    return dataCopy;
  };

  /**
   * A list of sort data functions. Sort data functions take a single piece of
   * data such as a title or a date and convert them into data to be sorted. For
   * example you might have a date from the server like "March 10th, 2016" but
   * you don't want to sort alphabetically so you could convert that into a date
   * with like `return new Date(...).getTime()`.
   * @private
   */
  BatchGeoAdminMapTable.prototype._headerSorters = {
    /**
     * Converts title's to lowercase
     * @returns {string}
     */
    title: function (data) {
      return data.toLowerCase();
    },
    /**
     * Converts string dates to getTime() dates
     * @returns {number}
     */
    date_updated: function (data) {
      return new Date(data).getTime();
    },
  };

  /**
   * Builds the required pieces for the admin sortable headers. For example, it
   * adds the sorting arrow container as well as adds the event handlers.
   *
   * @private
   */
  BatchGeoAdminMapTable.prototype._buildSortingHeaders = function () {
    var self = this;
    this._tableHeaderHandler = function () {
      var sortKey = $(this).data("sort-key");
      BatchGeoStore.dispatch({
        type: "ADMIN_MAP_TABLE_SORT_ORDER_INCREMENT",
        key: sortKey,
        sortableData: function (data) {
          var headerSorter = self._headerSorters[sortKey];
          return headerSorter ? headerSorter(data) : data;
        },
      });
    };

    var $sortKeyElements = $(this.elements.tableWrap).find("[data-sort-key]");
    $sortKeyElements.on("click", this._tableHeaderHandler);
    $sortKeyElements.append('<span class="admin-map-table-sort-arrow"></span>');
  };

  /**
   * Removes all the handlers and any DOM generated by BatchGeoAdminMapTable.
   */
  BatchGeoAdminMapTable.prototype.destroy = function () {
    $(window).off("resize", this._windowResizeHandler);
    $(this.elements.tableWrap)
      .find("th[data-sort-key]")
      .off("click", this._tableHeaderHandler);
  };

  /**
   * Creates a new Clusterize instance and saves it to a private property on the
   * prototype as _clusterize.
   *
   * @param {array} rows An array of HTML strings for Clusterize to inject
   *
   * @private
   */
  BatchGeoAdminMapTable.prototype._newClusterizeInstance = function (rows) {
    var self = this;
    this._clusterize = new Clusterize({
      rows: rows,
      scrollElem: this.elements.tableWrap,
      contentElem: this.elements.tableContent,
      show_no_data_row: false,
      callbacks: {
        clusterChanged: function () {
          self.settings.clusterChanged.apply(this, arguments);
        },
      },
    });
  };

  /**
   * Initializes the the search functionality which uses the BatchGeoSearch
   * module. It indexes the map titles to search. It also manages the "no
   * results" view (which we disable in the clusterize method specifically
   * because this handles it).
   *
   * @private
   */
  BatchGeoAdminMapTable.prototype._initSearch = function () {
    var self = this;
    this._batchGeoSearch = new BatchGeoSearch({
      container: this.elements.searchContainer,
      placeholder: "Map Name",
      batchGeoStoreDataSource: function (state) {
        return state.AdminMapTable.allRows;
      },
      // Only index the map titles (not dates, keywords, etc)
      dataIndexer: function (rows) {
        return rows.map(function (item) {
          return item.title;
        });
      },
      onQuery: function (query) {
        BatchGeoStore.dispatch({ type: "ADMIN_MAP_TABLE_SORT_ORDER_RESET" });

        // If the query is empty we will remove the active class
        this.$elements.searchInput.toggleClass("active", query !== "");
      },
      onResults: function (results) {
        // Reset the scroll position back to the top as you search
        self.elements.tableWrap.scrollTop = 0;

        // After getting the results update the state to trigger a re-render
        BatchGeoStore.dispatch({
          type: "ADMIN_MAP_TABLE_ROWS_FILTER",
          rows: results,
        });

        // If there was 0 results (!0 is true) then show the no results view
        $(self.elements.noResultsContainer).toggle(!results.length);
      },
      onClear: function () {
        BatchGeoStore.dispatch([
          { type: "ADMIN_MAP_TABLE_SORT_ORDER_RESET" },
          { type: "ADMIN_MAP_TABLE_ROWS_FILTER_RESET" },
        ]);

        $(self.elements.noResultsContainer).hide();

        this.$elements.searchInput.removeClass("active");
      },
    });
  };

  window.BatchGeoAdminMapTable = BatchGeoAdminMapTable;
})(window);
